<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }" autocomplete="off">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput">{{ $t('auth.email_address') }}</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" :placeholder="$t('auth.enter_email')" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">{{ $t('auth.password') }}</label>
          <router-link to="/customer-auth/password-reset" class="float-right">
            {{ $t('auth.forgot_password') }}
          </router-link>
          <input type="password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" :placeholder="$t('auth.password')" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" id="formType">
          <label class="custom-control-label" for="formType">{{ $t('auth.remember_me') }}</label>
        </div>
        <button type="submit" class="btn btn-primary float-right">{{ $t('auth.sign_in') }}</button>
      </div>
      <div class="apiErrors">{{ apiErrors }}</div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            {{ $t('auth.have_account') }}
            <router-link to="/customer-auth/signup" class="iq-waves-effect pr-4">
              {{ $t('auth.sign_up') }}
            </router-link>
          </span>
        <social-login-form :formtype="user.type"></social-login-form>
      </div>
    </form>
    <component :is="`style`" v-if="user.type === 'client'">
      .sign-in-12booky{
      background-color: #47cbff;
      background-image: url('{{ require('/src/assets/images/client.gif') }} ');
      background-position:top right;
      background-repeat: no-repeat;
      background-size: 30%;
      }
    </component>
    <component :is="`style`" v-if="user.type === 'business'">
      .sign-in-12booky{
      background-color: #63c9c7;
      background-position:top right;
      background-repeat: no-repeat;
      background-size: 40%;
      }
    </component>

    <component :is="`style`" v-if="user.type === 'business' || user.type === 'client'">
      .sign-in-12booky h1,
      .sign-in-12booky label,
      .sign-in-12booky span
      { color: #fff !important; }
      .sign-in-12booky input[type="text"],
      .sign-in-12booky input[type="email"],
      .sign-in-12booky input[type="password"] {
      background: #fff !important;
      }
      @media only screen and (max-width: 830px) {
        .sign-in-12booky{
          background-position:top left;
          background-size: 180px;
        }
      }
      .sign-in-12booky h1{
        margin-top: 100px !important;
      }
    </component>
  </ValidationObserver>
</template>

<script>
import SocialLoginForm from './SocialLoginForm'
import { mapActions } from 'vuex'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'SignIn1Form',
  components: { SocialLoginForm },
  props: ['email', 'password'],
  data: () => ({
    user: {
      email: '',
      password: '',
      type: 'client'
    },
    apiErrors: '',
    device: localStorage.getItem('fcm') || ''
  }),
  mounted () {
    this.user.email = this.$props.email
    this.user.password = this.$props.password
  },
  computed: {
    isLoggedIn: function () { return this.$store.getters.isLoggedIn }
  },
  methods: {
    ...mapActions({
      companyLogin: 'Company/companyLogin',
      customerLogin: 'Customer/customerLogin'
    }),
    async onSubmit () {
      this.apiErrors = ''
      if (this.user.password.length > 0) {
        const dataValues = {
          email: this.user.email,
          password: this.user.password,
          type: this.user.type
        }
        if (this.user.type === 'business') {
          this.companyLogin(dataValues)
            .then((theDate) => {
              const theUser = JSON.parse(theDate.data.user)
              if (this.device !== '') {
                const postData = {
                  token: this.device,
                  employee: theUser.token
                }
                Axios.post(API.API_STORE_TOKEN, postData)
                  .then(() => {
                    console.log('Push registration success')
                  })
                  .catch(() => {
                  })
              }
              this.$router.push('/app/calendar')
            })
            .catch(() => {
              this.apiErrors = this.$t('auth.wrong_combination')
            })
        } else {
          this.customerLogin(dataValues)
            .then((theDate) => {
              const theUser = JSON.parse(theDate.data.user)
              if (this.device !== '') {
                const postData = {
                  token: this.device,
                  customer: theUser.token
                }
                Axios.post(API.API_STORE_TOKEN, postData)
                  .then(() => {
                    console.log('Push registration success')
                  })
                  .catch(() => {
                  })
              }
              this.$router.push('/customer/services')
            })
            .catch(() => {
              this.apiErrors = this.$t('auth.wrong_combination')
            })
        }
      }
    },
    changeSwitch () {
      if (this.user.type === 'client') {
        this.user.type = 'business'
      } else {
        this.user.type = 'client'
      }
    }
  }
}
</script>
<style>
label[for="switch"] {
  width: 300px;
  background-color: rgba(0,0,0,.1);
  border-radius: 100px;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  height: 2.8rem;
}
.toggle {
  position: absolute;
  width: 150px;
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  box-shadow: 0 2px 15px rgba(0,0,0,.15);
  transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 2.8rem;
  border-radius: 100px;
}
.names {
  font-size: 15px;
  font-weight: bolder;
  width: 300px !important;
  margin-top: 3%;
  position: absolute;
  user-select: none;
}
.dark {
  opacity: .5;
}

#switch {
  display: none;
}
/* Toggle */
#switch:checked +  label .toggle{
  transform: translateX(100%);
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
}
#switch:checked + label .dark{
  opacity: 1;
  color: #fff !important;
}
#switch:checked + label .light{
  opacity: .5;
}

</style>
